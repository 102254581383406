<template>
  <UiPopup
    v-model="modelValue"
    size="extraBig"
    align-title-left
    popup-classes="max-h-[600px]"
    @update:model-value="modelValue = false"
  >
    <div
      class="absolute top-10 z-[1] flex flex-row items-center justify-between sm:w-[calc(91%-48px)] lg:w-[calc(91%-48px)] xl:w-11/12"
    >
      <h4>{{ currentStep.title }}</h4>
      <span class="text-caption-2 text-black-70">Step {{ currentStep.id }}/{{ steps.length }}</span>
    </div>
    <Transition name="fade" mode="out-in">
      <component
        :is="currentStep.component"
        v-model="leadEnrichment"
        @cancel="modelValue = false"
        @next="nextStep"
        @back="previousStep"
        @created="submit"
      />
    </Transition>
  </UiPopup>
</template>

<script setup lang="ts">
import type { LeadEnrichment } from '~/types'

const emits = defineEmits(['update:modelValue', 'input'])

const modelValue = defineModel<boolean>({ required: true })

const leadEnrichment = ref<Partial<LeadEnrichment>>({
  name: '',
  for_new_leads: false,
  for_old_leads: false,
  rules: [],
  actions: [],
  is_enabled: true,
})

const steps = ref([
  {
    id: 1,
    title: 'Create rule',
    component: shallowRef(resolveComponent('RotationDataEnrichmentRuleCreateForm')),
  },
  {
    id: 2,
    title: 'Preview and validation',
    component: shallowRef(resolveComponent('RotationDataEnrichmentRuleCreatePreviewAndValidation')),
  },
])
const currentStep = ref(steps.value[0])

const nextStep = () => {
  const nextStepIndex = steps.value.findIndex((step) => step.id === currentStep.value.id) + 1
  currentStep.value = steps.value[nextStepIndex]
}

const previousStep = () => {
  const previousStepIndex = steps.value.findIndex((step) => step.id === currentStep.value.id) - 1
  currentStep.value = steps.value[previousStepIndex]
}

const submit = () => {
  emits('input')
  modelValue.value = false
}
</script>

<style scoped></style>
