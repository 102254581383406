<template>
  <div class="flex flex-col">
    <div
      class="absolute left-0 top-0 flex h-full w-1/2 flex-col border-r-2 border-solid border-black-10 bg-black-03"
    ></div>
    <div class="relative flex w-full flex-row">
      <RotationDataEnrichmentRuleCreatePreview v-model="modelValue" />
      <RotationDataEnrichmentRuleCreateValidation v-model="modelValue" @validated="validated" />
    </div>
    <div class="mt-2 flex flex-row items-center justify-end gap-4">
      <UiButtonBase id="cancel" type="secondary" :disabled="loading" @click="$emit('back')">
        <UiIcon name="chevron-big-left" />
        Back to edit
      </UiButtonBase>
      <UiButtonBase id="create" :disabled="!isValid || loading" @click="create">Create</UiButtonBase>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui'
import type { LeadEnrichment } from '~/types'

const uiStore = useUiStore()

const emits = defineEmits(['back', 'created'])

const modelValue = defineModel<LeadEnrichment>({ required: true })

const existingLeadsCount = ref(0)
const isValid = ref(false)
const loading = ref(false)

const validated = (count: number) => {
  existingLeadsCount.value = count
  isValid.value = true
}

const create = async () => {
  if (!isValid.value) return
  loading.value = true
  try {
    await useCreateLeadEnrichment(modelValue.value)
    if (modelValue.value.for_old_leads && existingLeadsCount.value) {
      uiStore.showSnackBanner(
        `Created '${modelValue.value.name}' rule. Enriched ${existingLeadsCount.value} leads.`,
        'success'
      )
    } else {
      uiStore.showSnackBanner('Look for updates in Pool with tag “Enriched”.')
    }
    emits('created')
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
