<template>
  <div class="flex w-1/2 flex-col gap-8 pr-8">
    <div class="flex flex-col justify-start gap-2 text-start">
      <span>Lead data enrichment rule name</span>
      <span class="text-subhead-2">{{ modelValue.name }}</span>
      <div class="flex flex-row items-center gap-4">
        <UiInputCheckbox
          id="new-leads"
          v-model="modelValue.for_new_leads"
          name="new-leads"
          label="Apply to new leads"
          disabled
        />
        <UiInputCheckbox
          id="old-leads"
          v-model="modelValue.for_old_leads"
          name="old-leads"
          label="Apply to existing leads"
          disabled
        />
      </div>
    </div>
    <div class="flex flex-col justify-start gap-2 text-start">
      IF Lead parameters are:
      <div class="flex flex-row flex-wrap items-center gap-2">
        <div v-for="(rule, index) in modelValue.rules" :key="index" class="flex flex-row items-center gap-2">
          <UiTagSecondary :id="`tag_${index}`" right-icon="">
            <template #content>
              {{ PARAMETERS_NAMES_MAP.get(rule.rule_name) }} {{ rule.rule_operator }} {{ rule.rule_value }}
            </template>
          </UiTagSecondary>
          <span class="text-table-header text-black-40">{{ rule.union_operator }}</span>
        </div>
      </div>
    </div>
    <div class="flex flex-col justify-start gap-2 text-start">
      THEN Update Lead parameters:
      <div class="flex flex-row flex-wrap gap-2">
        <UiTagSecondary v-for="(action, index) in modelValue.actions" :id="`tag_${index}`" :key="index" right-icon="">
          <template #content>
            {{ ACTIONS_NAMES_MAP.get(action.field) }} =
            <span class="text-subhead-3">{{
              librariesMap.get(action.field)?.find((i) => i.value == action.value)?.text
            }}</span></template
          >
        </UiTagSecondary>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ACTIONS_NAMES_MAP, PARAMETERS_NAMES_MAP } from '~/constants'
import type { InputItem, LeadEnrichment } from '~/types'

defineEmits(['back'])

const modelValue = defineModel<LeadEnrichment>({ required: true })

onNuxtReady(async () => {
  await Promise.all([getLanguages(), getCountries()])
})

const librariesMap = ref(new Map<string, InputItem[]>(Array.from(ACTIONS_NAMES_MAP).map(([key]) => [key, []])))

const getLanguages = async () => {
  librariesMap.value.set('language_id', await useGetLanguageItems())
}

const getCountries = async () => {
  const countries = await useCountries()
  librariesMap.value.set(
    'country_id',
    countries.map((c) => ({ text: c.name, value: c.id }))
  )
  librariesMap.value.set(
    'phone_country_id',
    countries.map((c) => ({ text: `+${c.phone_code}`, value: c.phone_code }))
  )
  librariesMap.value.set(
    'timezone_id',
    countries.flatMap((c) => c.timezones.map((t) => ({ text: t.name, value: t.id })))
  )
}
</script>

<style scoped></style>
