<template>
  <div class="flex w-1/2 flex-col pl-8">
    <Transition name="fade" mode="out-in">
      <UiLoader v-if="validating" />
      <div v-else class="flex flex-col gap-4 text-start">
        <span>Validation</span>
        <div class="flex flex-col gap-4 text-start">
          <div class="text-body-2 flex flex-row items-center gap-2">
            <UiIcon name="check-filled" class="fill-white text-success-100" />
            {{ leadCount }} lead(s) found in Pool
          </div>
          <div v-if="!existingRules?.length" class="text-body-2 flex flex-row items-center gap-2">
            <div class="size-6">
              <UiIcon name="check-filled" class="fill-white text-success-100" />
            </div>
            Validation successful! This rule can be applied.
          </div>
          <div v-else class="flex flex-col gap-2">
            <div class="text-body-2 flex flex-row items-start gap-2">
              <div class="size-6">
                <UiIcon name="big-close-circle-filled" class="fill-white text-error-100" />
              </div>
              Rule with this parameters already exist. Change parameters or edit existing rule.
            </div>
            <div
              v-for="(rule, index) in existingRules"
              :key="index"
              class="ml-auto flex w-[calc(100%-32px)] flex-col gap-2 rounded-lg border border-solid border-dm-40 bg-black-03 px-3 py-4"
            >
              <div class="flex flex-row items-center justify-between">
                <span class="text-subhead-3">For {{ rule.enrichment_rule_name }}</span>
                <UiButtonGhost
                  id="open_rule"
                  @click="
                    navigateTo(`/rotations/data-enrichment?query=${rule.enrichment_rule_name}`, {
                      open: {
                        target: '_blank',
                      },
                    })
                  "
                >
                  Open rule
                  <UiIcon name="arrow-big-right" />
                </UiButtonGhost>
              </div>
              <UiTagSecondary :id="`tag_${index}`" right-icon="">
                <template #content>
                  {{ PARAMETERS_NAMES_MAP.get(rule.rule_name) }} {{ rule.rule_operator }} {{ rule.rule_value }}
                </template>
              </UiTagSecondary>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { PARAMETERS_NAMES_MAP } from '~/constants'
import { useUiStore } from '~/store/ui'
import type { LeadEnrichment, LeadEnrichmentRule } from '~/types'

const uiStore = useUiStore()

const emits = defineEmits(['validated'])

const modelValue = defineModel<LeadEnrichment>({ required: true })

const validating = ref(true)
const leadCount = ref(0)
const existingRules = ref<LeadEnrichmentRule[]>([])

onNuxtReady(async () => {
  await validateLeadEnrichment()
})

const validateLeadEnrichment = async () => {
  validating.value = true
  try {
    const response = await useValidateLeadEnrichment(JSON.parse(JSON.stringify(modelValue.value)))
    leadCount.value = response.data.leads_count
    existingRules.value = []
    emits('validated', leadCount.value)
  } catch (error: any) {
    if (error.data) {
      leadCount.value = error.data.leads_count
      existingRules.value = error.data.rules
    } else {
      uiStore.showSnackBanner(error.message, 'error')
    }
  } finally {
    validating.value = false
  }
}
</script>

<style scoped></style>
