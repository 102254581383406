<template>
  <div class="flex w-1/2 flex-col gap-2 text-start">
    THEN Update Lead parameters:
    <div class="flex max-h-[336px] flex-col gap-4 overflow-auto rounded-lg bg-black-03 p-4">
      <UiLoader v-if="loading" />
      <template v-else>
        <TransitionGroup name="fade">
          <div v-for="(action, index) in modelValue" :key="index" class="flex w-full flex-row items-center gap-3">
            <div class="flex flex-row items-center gap-2">
              <div class="flex flex-1">
                <UiInputSelect
                  :id="`action_field_${index}`"
                  v-model="action!.field"
                  :name="`action_field_${index}`"
                  :items="actionsAvailable"
                  placeholder="Select parameter"
                  :error="useGetFieldErrorsByIndex(v, 'actions', 'field', index)"
                  @update:model-value="action.value = ''"
                ></UiInputSelect>
              </div>
              <div class="flex flex-1">
                <UiInputSelect
                  v-model="action.value"
                  :items="librariesMap.get(action.field) || []"
                  name="name"
                  placeholder="Select option"
                  :error="useGetFieldErrorsByIndex(v, 'actions', 'value', index)"
                />
              </div>
            </div>

            <div class="w-4">
              <UiIcon
                name="big-close-circle-filled"
                size="xxs"
                class="!cursor-pointer text-primary-100"
                @click="removeCondition(index)"
              />
            </div>
          </div>
        </TransitionGroup>
        <UiButtonGhost v-if="modelValue!.length < 4" id="add-condition" @click="addCondition">
          <UiIcon name="big-add-circle" />
          Add condition
        </UiButtonGhost>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Validation } from '@vuelidate/core'
import { ACTIONS_NAMES_MAP } from '~/constants'
import type { InputItem, LeadEnrichmentAction } from '~/types'

const modelValue = defineModel<LeadEnrichmentAction[]>()

type Props = {
  v: Validation
}
defineProps<Props>()

const loading = ref(true)

const actionsAvailable = ref(Array.from(ACTIONS_NAMES_MAP).map(([key, value]) => ({ value: key, text: value })))
const librariesMap = ref(new Map<string, InputItem[]>(Array.from(ACTIONS_NAMES_MAP).map(([key]) => [key, []])))

onNuxtReady(async () => {
  await Promise.all([getLanguages(), getCountries()])
  loading.value = false
})

const getLanguages = async () => {
  librariesMap.value.set('language_id', await useGetLanguageItems())
}

const getCountries = async () => {
  const countries = await useCountries()
  librariesMap.value.set(
    'country_id',
    countries.map((c) => ({ text: c.name, value: c.id }))
  )
  librariesMap.value.set(
    'phone_country_id',
    countries.map((c) => ({ text: `+${c.phone_code}`, value: c.id }))
  )
  librariesMap.value.set(
    'timezone_id',
    countries.flatMap((c) => c.timezones.map((t) => ({ text: t.name, value: t.id })))
  )
}

const addCondition = () => {
  modelValue.value!.push({
    field: '',
    value: '',
  })
}

const removeCondition = (index: number) => {
  modelValue.value!.splice(index, 1)
}
</script>

<style scoped></style>
